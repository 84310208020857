import { HttpMutators } from "./http-mutators";
import { useHttp } from "./http";

export const useProductService = () => {
  const { post, patch, remove } = useHttp("/products");

  return {
    getProductsByProperty: (
      mutators: HttpMutators,
      payload: any,
      params?: any
    ) => post(mutators, { url: "/property", payload, params }),
    addProduct: (mutators: HttpMutators, payload: any) =>
      post(mutators, {
        headers: { "Content-Type": "multipart/form-data" },
        payload,
      }),
    editProduct: (mutators: HttpMutators, id: string, payload: any) =>
      patch(mutators, {
        url: `/${id}`,
        headers: { "Content-Type": "multipart/form-data" },
        payload,
      }),
    removeProduct: (mutators: HttpMutators, id: string) =>
      remove(mutators, { url: `/${id}` }),
    toggleProductSpecialOffer: (
      mutators: HttpMutators,
      payload: { productId: string; isActive: boolean }
    ) =>
      patch(mutators, {
        url: `/special-offers/toggle`,
        headers: { "Content-Type": "application/json" },
        payload,
      }),
    resetSeenByProductSpecialOffer: (
      mutators: HttpMutators,
      payload: { productId: string }
    ) => {
      return patch(mutators, {
        url: `/special-offers/reset-seen-by`,
        headers: { "Content-Type": "application/json" },
        payload,
      });
    },
  };
};
